import { render, staticRenderFns } from "./CustomerList.vue?vue&type=template&id=4efecf30&scoped=true"
import script from "./CustomerList.vue?vue&type=script&lang=js"
export * from "./CustomerList.vue?vue&type=script&lang=js"
import style0 from "./CustomerList.vue?vue&type=style&index=0&id=4efecf30&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4efecf30",
  null
  
)

component.options.__file = "CustomerList.vue"
export default component.exports